import React, { useEffect, useState } from "react";
import logo from "../images/logo-muc.svg";
import api from "../services/api";
import { useQuery } from "../hooks";
import { Link } from "react-router-dom";
import Loader from "./Loader";

export default function GetConfirmation() {
  let query = useQuery();

  const [loading, setLoading] = useState(true);
  const [entry, setEntry] = useState(null);

  useEffect(() => {
    api.entries.getOne(query.get("entry")).then((e) => {
      setEntry(e);
      setLoading(false);
    });
  }, [query]);

  function parseDateStr(dateStr) {
    if (dateStr.includes("T")) {
      const date = new Date(dateStr);
      // return `${date.getDate()}/${(date.getMonth()+1).toString().padStart(2,'0')}/${date.getFullYear()}`
      return date.toLocaleDateString("es-py", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      });
    } else {
      const parts = dateStr.split("-");
      return `${parts[2]}/${parts[1]}/${parts[0]}`;
    }
  }

  return (
    <div className="md:grid place-items-center h-screen sm:rounded-lg body-inscription">
      <Link to="/">
        <img src={logo} alt="logo" className="logo-in-cedula" />
      </Link>
      <div className="px-4 py-5 sm:p-6 phone-pad-c">
        <h1 className="font-extrabold text-gray-800 title-cedula">
          Confirmación de inscripción
        </h1>
        {loading && <Loader />}
        {!loading && entry && (
          <>
            <div className="py-10 px-5 md:px-10 bg-white card-cedula-ph max-w-2xl">
              <div className="md:grid gap-8 md:grid-cols-6 md:pb-5">
                <div className="col-span-3 sm:col-span-3">
                  <p className="text-gray-500">Nombre y apellido</p>
                  <p>{`${entry?.missionary?.firstName} ${entry?.missionary?.lastName}`}</p>
                </div>
                <div className="col-span-3 sm:col-span-3">
                  <p className="text-gray-500">Cédula</p>
                  <p>{entry?.missionary?.issuedId}</p>
                </div>
              </div>

              <br />

              <div className="md:grid gap-8 md:grid-cols-6 md:pb-5">
                <div className="col-span-3 sm:col-span-3">
                  <p className="text-gray-500">Fecha de nacimiento</p>
                  <p>
                    {entry?.missionary?.birthDate &&
                      parseDateStr(entry?.missionary?.birthDate)}
                  </p>
                </div>
                <div className="col-span-3 sm:col-span-3">
                  <p className="text-gray-500">Fecha y hora de inscripción</p>
                  <p>{entry?.entryDate && parseDateStr(entry?.entryDate)}</p>
                </div>
              </div>

              <br />

              <div className="md:grid gap-8 md:grid-cols-6 md:pb-5">
                <div className="col-span-3 sm:col-span-3">
                  <p className="text-gray-500">Correo electrónico</p>
                  <p className="break-words">{`${entry?.missionary?.email}`}</p>
                </div>
                <div className="col-span-3 sm:col-span-3">
                  <p className="text-gray-500">Teléfono</p>
                  <p>{`${entry?.missionary?.cellphone}`}</p>
                </div>
                <div className="col-span-3 sm:col-span-3">
                  <p className="text-gray-500">Encuentro previo</p>
                  <p>
                    {`${entry?.meetingGoing.title}` +
                      " " +
                      parseDateStr(entry?.meetingGoing.start)}
                  </p>
                </div>
              </div>

              <br />

              <div>
                <p className="text-gray-500">Lista de espera</p>
                {entry?.isInWaitingList ? (
                  <p className="text-red-700">Estás lista de espera</p>
                ) : (
                  <p className="text-green-700">No estás en lista de espera</p>
                )}
              </div>

              <br />
              {/*<div>
                <p className="text-gray-500">Vacunación COVID-19</p>
                {entry.isVaccinated && <p className="text-green-700">SI</p>}
                {!entry.isVaccinated && (
                  <p className="text-red-700 font-semibold iph-se-lout">
                    Estás a un paso de finalizar tu inscripción. No logramos
                    encontrar tu registro de vacunación, favor consultar en{' '}
                    <a
                      href="https://datos.mspbs.gov.py/vacunados/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://datos.mspbs.gov.py/vacunados/
                    </a>{' '}
                    o mandanos una foto de tu carnet de vacunación a{' '}
                    <a href="mailto:inscripciones@muc.org.py">
                      inscripciones@muc.org.py
                    </a>
                    .
                  </p>
                )}
                </div>*/}

              <br />
              <Link to="/">
                <button
                  type="submit"
                  className="w-full font-medium text-white border border-transparent shadow-sm rounded-md btn-sig"
                >
                  Volver al inicio
                </button>
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
