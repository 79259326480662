import logo from "../images/logo-muc.svg";
import { TrackInscription2 } from "./TrackInscription";
import { useNavigate } from "react-router-dom";
import useInput from "../hooks/use-input";
import Toggle from "./ToggleInputs";
import { useState, useEffect } from "react";
import api from "../services/api";
import { useQuery } from "../hooks";
import { Link } from "react-router-dom";

export default function GetMisionData(props) {
  const [towns, setTowns] = useState([]);
  const [mission, setMission] = useState(null);
  const [acceptsTerms, setAcceptsTerms] = useState(false);

  const [hasVehicle, setHasVehicle] = useState(false);
  const [hasFirstAid, setHasFirstAid] = useState(false);
  const [hasPrisionExp, setHasPrisionExp] = useState(false);
  const [hasFoodIssue, setHasFoodIssue] = useState(false);
  const [hasHealthIssue, setHasHealthIssue] = useState(false);
  const [wantsGuide, setWantsGuide] = useState(false);
  const [wantsKnow, setWantsKnow] = useState(false);
  const [meetingList, setMeetingList] = useState([]);

  let query = useQuery();
  let navigate = useNavigate();

  //PUEBLO 1
  const {
    value: enteredTown1,
    setValue: setEnteredTown1,
    isValid: enteredTown1IsValid,
    hasError: town1HasError,
    valueChangedHandler: town1ChangedHandler,
    inputBlurHandler: town1BlurHandler,
    // onChange: onlyNum,
    resetForm: resetTown1,
  } = useInput((value) => value.trim() !== "");

  //PUEBLO 2
  const {
    value: enteredTown2,
    setValue: setEnteredTown2,
    isValid: enteredTown2IsValid,
    hasError: town2HasError,
    valueChangedHandler: town2ChangedHandler,
    inputBlurHandler: town2BlurHandler,
    // onChange: onlyNum,
    resetForm: resetTown2,
  } = useInput((value) => value.trim() !== "");

  //REMERA
  const {
    value: enteredRemera,
    setValue: setRemera,
    isValid: enteredRemeraIsValid,
    hasError: remeraHasError,
    valueChangedHandler: remeraChangedHandler,
    inputBlurHandler: remeraBlurHandler,
    // onChange: onlyNum,
    resetForm: resetRemera,
  } = useInput((value) => value !== "placeholder");

  //Encuentro previo
  const {
    value: enteredEncuentro,
    setValue: setEncuentro,
    isValid: enteredEncuentroIsValid,
    hasError: encuentroHasError,
    valueChangedHandler: encuentroChangedHandler,
    inputBlurHandler: encuentroBlurHandler,
    // onChange: onlyNum,
    resetForm: resetEncuentro,
  } = useInput((value) => value.trim() !== "");

  const {
    value: enteredOtherConditions,
    hasError: otherConditionsHasError,
    isValid: otherConditionsIsValid,
    valueChangedHandler: otherConditionsChangedHandler,
    inputBlurHandler: otherConditionsBlurHandler,
  } = useInput((value) => (value) => value.trim() !== "");

  const {
    value: enteredOtherHealthConditions,
    hasError: otherHealthConditionsHasError,
    isValid: otherHealthConditionsIsValid,
    valueChangedHandler: otherHealthConditionsChangedHandler,
    inputBlurHandler: otherHealthConditionsBlurHandler,
  } = useInput((value) => (value) => value.trim() !== "");

  //AÑOS DE MISION
  const {
    value: enteredMisionYears,
    setValue: setMisionYears,
    hasError: misionYearsHasError,
    isValid: enteredMisionYearsIsValid,
    valueChangedHandler: misionYearsChangedHandler,
    inputBlurHandler: misionYearsBlurHandler,
    // onChange: onlyNum,
    resetForm: resetMisionYears,
  } = useInput((value) => typeof parseInt(value) === "number", "0");

  //NOMBRE DEL CONTACTO
  const {
    value: enteredContact,
    setValue: setContact,
    isValid: enteredContactIsValid,
    hasError: contactHasError,
    valueChangedHandler: contactChangedHandler,
    inputBlurHandler: contactBlurHandler,
    // onChange: onlyNum,
    resetForm: resetContact,
  } = useInput((value) => value.trim() !== "");

  //NUM DEL CONTACTO
  const {
    value: enteredContactNum,
    setValue: setContactNum,
    isValid: enteredContactNumIsValid,
    hasError: contactNumHasError,
    valueChangedHandler: contactNumChangedHandler,
    inputBlurHandler: contactNumBlurHandler,
    onlyNum,
  } = useInput((value) => value.trim() !== "");

  //TERMS & CONDITIONS
  const { value: enteredTerms } = useInput(
    (value) => typeof value === "boolean",
    false
  );

  useEffect(() => {
    async function fetchData() {
      const _mission = await api.missions.getOne("active");
      setMission(_mission);
      api.meetings
        .getMulti("title", null, 100, 0, { mission: _mission.id })
        .then((data) => {
          setMeetingList(data);
        });
      api.towns
        .getMulti("name", null, 100, 0, { missions: _mission.id })
        .then(setTowns);
    }
    fetchData();
  }, [query]);

  const isFormValid = () => {
    if (
      enteredRemeraIsValid &&
      enteredMisionYears >= 0 &&
      enteredContactIsValid &&
      enteredEncuentroIsValid &&
      enteredContactNumIsValid &&
      acceptsTerms
    )
      return true;
    return false;
  };

  const submissionHandler = (e) => {
    e.preventDefault();

    const data = {
      mission: mission.id,
      missionary: query.get("missionaryId"),
      town1: enteredTown1 > 0 ? enteredTown1 : null,
      town2: enteredTown2 > 0 ? enteredTown2 : null,
      shirtSize: enteredRemera,
      hasVehicle,
      hasFirstAidExperience: hasFirstAid,
      hasPrisonExperience: hasPrisionExp,
      wantsGuide,
      otherConditions: `${enteredOtherConditions} | ${enteredOtherHealthConditions}`,
      missionYears: enteredMisionYears,
      emergencyContactName: enteredContact,
      emergencyContactPhone: enteredContactNum,
      acceptTermsAndConditions: acceptsTerms,
      meetingGoing: enteredEncuentro,
      wantsToKnowAboutManosLibres: wantsKnow,
    };

    api.entries.create(data).then((instance) => {
      navigate(`/confirm-inscription?entry=${instance.id}`);
    });
  };
  useEffect(() => {
    api.missions.getOne("active").then(setMission);
    api.towns.getMulti().then(setTowns);
  }, [query]);

  const parseMeetingDate = (meeting) => {
    const start = new Date(meeting.start);
    return start.toLocaleString("es-PY", {
      day: "2-digit",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <>
      <div className="md:grid md:grid-cols-4 md:gap-6 h-screen">
        <div className="md:col-span-1 bg-white pt-5 pl-10 pb-10">
          <div className="px-4 sm:px-0">
            <Link to="/">
              <img src={logo} alt="logo" className="logo-in-pd logo-in-phone" />
            </Link>
            <TrackInscription2 />
          </div>
        </div>
        <div className="mt-5 md:mt-8 md:col-span-3 p-3">
          <form action="#" method="POST" onSubmit={submissionHandler}>
            <h3 className="font-extrabold text-gray-700 text-3xl title-get-data">
              Datos de la misión
            </h3>
            <div className="md:mx-10 shadow overflow-hidden sm:rounded-md phone-layout">
              <div className="px-4 py-5 bg-white sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  {/* Pueblo 1 */}
                  <div className="col-span-6 sm:col-span-3 sm:col-start-1 sm:col-end-4">
                    <label
                      htmlFor="pueblo1-input"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Opción de pueblo 1{" "}
                      <span className="require-field">*</span>
                    </label>
                    <select
                      id="pueblo1-input"
                      name="pueblo1-input"
                      autoComplete="off"
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      value={enteredTown1}
                      onChange={town1ChangedHandler}
                      onBlur={town1BlurHandler}
                    >
                      <option>--</option>
                      {towns.map((town) => (
                        <option value={town.id} key={town.id}>
                          {town.name}
                        </option>
                      ))}
                      <option value={0} key={0}>
                        Me quiero sorprender (ninguno)
                      </option>
                    </select>
                    {town1HasError && (
                      <p className="error-text">Debe de ingresar un pueblo.</p>
                    )}
                  </div>

                  {/* Pueblo 2 */}
                  <div className="col-span-6 sm:col-span-3 sm:col-start-1 sm:col-end-4">
                    <label
                      htmlFor="pueblo2-input"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Opción de pueblo 2{" "}
                      <span className="require-field">*</span>
                    </label>
                    <select
                      id="pueblo2-input"
                      name="pueblo2-input"
                      autoComplete="off"
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      value={enteredTown2}
                      onChange={town2ChangedHandler}
                      onBlur={town2BlurHandler}
                    >
                      <option>--</option>
                      {towns.map((town) => (
                        <option value={town.id} key={town.id}>
                          {town.name}
                        </option>
                      ))}
                      <option value={0} key={0}>
                        Me quiero sorprender (ninguno)
                      </option>
                    </select>
                    {town2HasError && (
                      <p className="error-text">Debe de ingresar un pueblo.</p>
                    )}
                  </div>
                </div>

                <br />
                <hr />
                <br />
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="encuentro-input"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Encuentro al que asistirás{" "}
                      <span className="require-field">*</span>
                    </label>
                    <select
                      id="encuentro-input"
                      name="encuentro-input"
                      autoComplete="off"
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      value={enteredEncuentro}
                      onChange={encuentroChangedHandler}
                      onBlur={encuentroBlurHandler}
                    >
                      <option>--</option>
                      {meetingList.map((m) => (
                        <option value={m.id} key={m.id}>
                          {m.title} ({parseMeetingDate(m)})
                        </option>
                      ))}
                    </select>
                    {encuentroHasError && (
                      <p className="error-text">
                        Debes seleccionar un encuentro.
                      </p>
                    )}
                  </div>
                </div>

                <br />

                {/* Tamaño de remera */}
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="remera-input"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Tamaño de remera <span className="require-field">*</span>
                    </label>
                    <select
                      id="remera-input"
                      name="remera-input"
                      autoComplete="off"
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      value={enteredRemera}
                      onChange={remeraChangedHandler}
                      onBlur={remeraBlurHandler}
                    >
                      <option value="placeholder" placeholder="true">
                        --
                      </option>
                      <option value="S">Pequeño</option>
                      <option value="M">Mediano</option>
                      <option value="L">Grande</option>
                      <option value="XL">Extra Grande</option>
                      {/* <option value="NO">No quiero remera</option> */}
                    </select>
                    {remeraHasError && (
                      <p className="error-text">
                        Debe de ingresar un tamaño de remera.
                      </p>
                    )}
                  </div>
                </div>

                <br />
                <hr />
                <br />

                {/* Toggles */}
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3 sm:col-start-1 sm:col-end-4">
                    <Toggle
                      text="Tengo experiencia en apostolados penitenciarios"
                      onChange={setHasPrisionExp}
                      value={hasPrisionExp}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3 sm:col-start-1 sm:col-end-4">
                    <Toggle
                      text="Puedo llevar camioneta para la misión"
                      onChange={setHasVehicle}
                      value={hasVehicle}
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3 sm:col-start-1 sm:col-end-4">
                    <Toggle
                      text="Tengo conocimientos para dar primeros auxilios"
                      onChange={setHasFirstAid}
                      value={hasFirstAid}
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3 sm:col-start-1 sm:col-end-4">
                    <Toggle
                      text="Quiero comprar un manual misionero"
                      onChange={setWantsGuide}
                      value={wantsGuide}
                    />
                  </div>
                </div>

                <hr className="my-7" />

                <div className="grid grid-cols-6 gap-x-6 gap-y-2 f-issue-lout-ph">
                  {/* Toggles problemas */}
                  <div className="col-span-6 sm:col-span-3">
                    <Toggle
                      text="Tengo alguna restricción alimentaria"
                      onChange={setHasFoodIssue}
                    />
                  </div>
                  <br />
                  {hasFoodIssue && (
                    <div className="col-span-6 sm:col-span-3">
                      <input
                        type="text"
                        placeholder="Intolerancia a la lactosa, celiaquía, dieta vegana/vegetariana, etc"
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ph-lout-f-issue-input"
                        value={
                          enteredOtherConditions.charAt(0).toUpperCase() +
                          enteredOtherConditions.slice(1)
                        }
                        onChange={otherConditionsChangedHandler}
                      />
                    </div>
                  )}
                  <br />
                  <div className="col-span-6 sm:col-span-3">
                    <Toggle
                      text="Tengo algún inconveniente de salud / estoy tomando medicamentos"
                      onChange={setHasHealthIssue}
                    />
                  </div>
                  <br />
                  {hasHealthIssue && (
                    <div className="col-span-6 sm:col-span-3">
                      <input
                        type="text"
                        placeholder="Medicamentos por alergia o diabetes, asma, etc."
                        className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ph-lout-f-issue-input"
                        value={
                          enteredOtherHealthConditions.charAt(0).toUpperCase() +
                          enteredOtherHealthConditions.slice(1)
                        }
                        onChange={otherHealthConditionsChangedHandler}
                      />
                    </div>
                  )}
                </div>

                <br />
                <hr />
                <br />

                <div className="grid grid-cols-6 gap-6">
                  {/* Años de mision */}
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="años-mision-input"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Años de misión en las MUC u otras misiones
                    </label>
                    <input
                      type="number"
                      id="años-mision-input"
                      name="años-mision-input"
                      placeholder="0"
                      autoComplete="off"
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      value={enteredMisionYears}
                      onChange={misionYearsChangedHandler}
                      onBlur={misionYearsBlurHandler}
                    ></input>
                    {misionYearsHasError && (
                      <p className="error-text">
                        Debe de ingresar los años de misión que tiene.
                      </p>
                    )}
                  </div>
                </div>

                <br />
                <hr />
                <br />

                {/* Contacto de emergencia  */}
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="emergency-name-input"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Nombre de contacto de emergencia{" "}
                      <span className="require-field">*</span>
                    </label>
                    <input
                      type="text"
                      name="emergency-name-input"
                      id="emergency-name-input"
                      autoComplete="off"
                      placeholder="Nombre y Apellido"
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      value={
                        enteredContact.charAt(0).toUpperCase() +
                        enteredContact.slice(1)
                      }
                      onChange={contactChangedHandler}
                      onBlur={contactBlurHandler}
                    />
                    {contactHasError && (
                      <p className="error-text">
                        Debe de ingresar un contacto de emergencia.
                      </p>
                    )}
                  </div>
                  <div className="col-span-6 sm:col-span-3 sm:col-start-1 sm:col-end-4">
                    <label
                      htmlFor="emergency-tel-input"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Celular del contacto{" "}
                      <span className="require-field">*</span>
                    </label>
                    <input
                      type="text"
                      name="emergency-tel-input"
                      id="emergency-tel-input"
                      autoComplete="off"
                      placeholder="Celular"
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      value={enteredContactNum}
                      onChange={(contactNumChangedHandler, onlyNum)}
                      onBlur={contactNumBlurHandler}
                    />
                    {contactNumHasError && (
                      <p className="error-text">
                        Debe de ingresar el número del contacto de emergencia.
                      </p>
                    )}
                  </div>
                </div>

                <br />
                <hr />
                <br />

                {/* Check boxes */}
                <div className="grid grid-cols-1 gap-6">
                  <fieldset className="space-y-5">
                    <div className="relative flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          id="comments"
                          aria-describedby="comments-description"
                          name="comments"
                          type="checkbox"
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          onChange={() => setAcceptsTerms(!acceptsTerms)}
                        />
                      </div>
                      <div className="flex flex-col gap-6">
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="comments"
                            className="font-medium text-gray-700"
                          >
                            Acepto los{" "}
                            <a
                              href="https://www.muc.org.py/bases-y-condiciones/"
                              rel="noreferrer"
                              target="_blank"
                            >
                              <strong>Términos y Condiciones</strong>
                            </a>
                            <span className="require-field">*</span>
                          </label>
                          <span
                            id="comments-description"
                            className="text-gray-500"
                          >
                            <span> {props.additionalText} </span>
                          </span>
                        </div>
                        <div className="w-full">
                          <Toggle
                            text="Quiero enterarme cómo ayudar a las Misiones a través del proyecto ‘Manos Libres’"
                            onChange={setWantsKnow}
                            value={wantsKnow}
                          />
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div className="px-4 py-3 sm:px-6 text-center">
                <button
                  type="submit"
                  className="w-full font-medium text-white border border-transparent shadow-sm rounded-md btn-sig"
                  disabled={!isFormValid()}
                >
                  Inscribirme
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
